* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: 'Open Sans';
  src: url('static/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Bold';
  src: url('static/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans ExtraBold';
  src: url('static/assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans Light';
  src: url('static/assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('static/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/*Muli*/
@font-face {
  font-family: 'Muli';
  src: url('static/assets/fonts/Muli-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Muli Bold';
  src: url('static/assets/fonts/Muli-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Muli Black';
  src: url('static/assets/fonts/Muli-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Muli ExtraBold';
  src: url('static/assets/fonts/Muli-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Muli Light';
  src: url('static/assets/fonts/Muli-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Muli SemiBold';
  src: url('static/assets/fonts/Muli-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*Container for media queries*/

.container-habi {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 414px) {
  .container-habi {
    max-width: 736px;
  }
}

@media screen and (min-width: 768px) {
  .container-habi {
    max-width: 992px;
  }
}

@media screen and (min-width: 1024px) {
  .container-habi {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1280px) {
  .container-habi {
    max-width: 1280px;
  }
}
